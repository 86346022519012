<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
      <!-- <li class="content-header-title">Master</li> -->
      <li class="breadcrumb-item">
          <a href="dashboard.html"><i class="bx bx-home-alt"></i></a>
      </li>
       <li *ngFor="let breadcrumb of subHeaderData.breadcrumbs" class="breadcrumb-item">
          <a *ngIf="breadcrumb.url; else elseBlock" href="" [routerLink]="[breadcrumb.url]">{{breadcrumb.localeKey}}</a>
          <ng-template #elseBlock>
            <a class="breadcrumb-item active" aria-current="page" href="javascript:Void();">{{breadcrumb.localeKey}}</a>
          </ng-template>
        </li>
  </ol>
</nav> 