import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';



// routing
import {AppRoutingModule} from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// Common
import { CommonService } from './common/service/common.service';
import {CanAuthActivate, CanLoginActivate } from './common/service/auth-guard';
import { ErrorMessages } from './common/service/errorMessages';


// plugins
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

// component
import { AppComponent } from './app.component';
// import { Settings1Component } from './modules/settings1/settings1.component';


@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    // Settings1Component
  ],
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  exports: [],
  providers: [
    CanLoginActivate,
    CommonService,
    CanAuthActivate,
    BsModalRef,
    BsDropdownDirective,
    ErrorMessages
  ]
  
})
export class AppModule { }


