import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '../../common/service/theme/theme.service';
import { ShowErrorService } from '../../common/service/show-error.service';


// environment

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  passwordType: boolean;
  public user: any = {};
  uploadImageUrl = environment.uploadImgUrl;

 

 constructor( private themeService: ThemeService, private router: Router,
   private localStorageService: LocalStorageService, private commonService: CommonService, private showErrorService: ShowErrorService
  ) { }

  ngOnInit(): void {
    var isDark=this.localStorageService.getToken('theme')=="Dark"?true:false;
    this.themeService.switchThemeAfterLogin(isDark);
    if(isDark)
    document.getElementById("loginSwitchTheme").setAttribute('checked','');
  }

  onLogin(form) {
    var numbers = /^[0-9]+$/;
    var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    if((form.value.emailOrPhone.match(email))||(form.value.emailOrPhone.match(numbers) && form.value.emailOrPhone.length == 10)) {
       this.user={
       authName:form.value.emailOrPhone,
       password:form.value.password
     }
      this.commonService.callApi(this.callAPIConstants.AdminLogin, this.user, 'post', true, false).then((success) => {
        if (success.status === 1) {
         this.localStorageService.setToken('accessToken', success.access_token);
         this.localStorageService.setToken('firstname', success.data.firstname);
         this.localStorageService.setToken('lastname', success.data.lastname);
        if(success.data.photo != undefined || success.data.photo != null){
          this.localStorageService.setToken('photo', success.data.photo);
         }
         this.localStorageService.setToken('theme',success.data.theme);
         this.localStorageService.setToken('menuAlignment',success.data.menuAlignment);
         var isDark=success.data.theme=="Dark"?true:false;
         this.themeService.switchThemeAfterLogin(isDark);
         this.showErrorService.popToast('success', success.message);
         this.router.navigate([this.URLConstants.DASHBOARD]);
      } else {
         this.showErrorService.popToast('error', success.message);
      }
     });
    }

    
    }
  /*************************************************************/

  


  

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
 }




  toggleFieldTextType() {
  this.passwordType = !this.passwordType;
  }

toggleTheme() {
  var theme =  this.themeService.switchTheme();
  this.localStorageService.setToken('theme', theme);  
}

}
