import { Component, OnInit } from '@angular/core';

// Constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

// Interface
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// Services
import { CommonService } from 'src/app/common/service/common.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: []
})
export class MasterComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  // public accessPermission: any;

  tableSetupData: any = {};
  tabs: any = {};

  // breadcrump variable
  public subHeaderData: any;
  public tabName: any = 'Interests & Hobbies';
  public breadcrumbs: IBreadcrumbs[];
 

  constructor( private commonService: CommonService, private showErrorService: ShowErrorService) { }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.setDTableInitialData();
    this.tabs.country = true;
  }

    // To set dynamic breadcrump

      setBreadcrumbs() {
        this.breadcrumbs = [
          { localeKey: 'Master', url: this.URLConstants.MASTER },
          { localeKey:  this.tabName, url: null },
        ];
        this.subHeaderData = {
          breadcrumbs: this.breadcrumbs,
        };
      }

  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },
      { type: 'text', colName: 'Name', colFieldname: 'Name', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'text', colName: 'Latest Updated By', colFieldname: 'Latest Updated By', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'text', colName: 'Created At', colFieldname: 'Created At', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'text', colName: 'Last Created Date', colFieldname: 'Last Created Date', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'switch', colName: 'Status', colFieldname: 'status', sort: false, columnVisibility: true, isVisible: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
    ];
    //if (!this.accessPermission.edit && !this.accessPermission.delete) {
      // this.getPermissionsService.hideActionInTable(tempData);
   // }
    this.tableSetupData.cols = tempData;
    // this.tableSetupData.type = 'countriesListing';
    this.tableSetupData.saveColumnKey = 'countriesListing';

    this.tableSetupData.actions = [
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit',
        type: 'icon'
      },
      {
        id: 3, buttonTitle: 'Delete', tooltip: 'Delete', class: 'bx bx-trash-alt',
        type: 'icon'
      },
    ];
    this.tableSetupData.params = { deleteParams: 'countriesIds', statusParams: 'countriesIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: false,
      showExport: false,
      showColumnHide: false,
      showFilter: true,
      showFilterExport: false,
      showSaveFilter: false,
      modal: true,
      search: true,
      showButton: { buttonName: 'Add' },
    }
  }

  // setDTableInitialData1() {
  //   const tempData = [
  //     // { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },
  //     { type: 'text', colName: 'Country Name', colFieldname: 'countryName', sort: true, columnVisibility: true, isVisible: true, filter: true },
  //     { type: 'text', colName: 'Currency', colFieldname: 'currency', sort: true, columnVisibility: true, isVisible: true, filter: true },
  //     { type: 'switch', colName: 'status', colFieldname: 'status', sort: false, columnVisibility: true, isVisible: true, filter: true },
  //     { type: 'action', colName: 'Actions', colFieldname: '', isVisible: true },
  //   ];
  //   this.tableSetupData.cols = tempData;
  //   this.tableSetupData.type = 'currencyListing';
  //   this.tableSetupData.saveColumnKey = 'currenciesListing';
  //   this.tableSetupData.actions = [
  //     {
  //       id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit',
  //       type: 'icon'
  //     },
  //     {
  //       id: 3, buttonTitle: 'Delete', tooltip: 'Delete', class: 'bx bx-trash-alt',
  //       type: 'icon'
  //     },
  //   ];
  //   this.tableSetupData.params = { deleteParams: 'currenciesIds', statusParams: 'currenciesIds' };
  //   this.tableSetupData.conditions = {
  //     showTableHeader: true,
  //     showTableFooter: true,
  //     showApplyStatus: false,
  //     showExport: false,
  //     showColumnHide: false,
  //     showFilter: false,
  //     showFilterExport: false,
  //     showSaveFilter: false,
  //     // showDateYear: true,
  //     modal: true,
  //     search: true,
  //     showButton: { buttonName: 'Add' },
  //   };
  // }
 
  // setDTableInitialData2() {
  //   const tempData = [
  //     // { type: 'text', colName: 'Select', colFieldname: '', isVisible: true },
  //     { type: 'text', colName: 'Country Name', colFieldname: 'countryName', sort: true, columnVisibility: true, isVisible: true, filter: true },
  //     { type: 'text', colName: 'TimeZone', colFieldname: 'timezone', sort: true, columnVisibility: true, isVisible: true, filter: true },
  //     { type: 'switch', colName: 'Status', colFieldname: 'status', sort: false, columnVisibility: true, isVisible: true, filter: true },
  //     { type: 'action', colName: 'Actions', colFieldname: '', isVisible: true },
  //   ];
  //   this.tableSetupData.cols = tempData;
  //   this.tableSetupData.type = 'timezoneListing';
  //   this.tableSetupData.saveColumnKey = 'timezoneListing';
  //   this.tableSetupData.actions = [
  //     {
  //       id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit',
  //       type: 'icon'
  //     },
  //     {
  //       id: 3, buttonTitle: 'Delete', tooltip: 'Delete', class: 'bx bx-trash-alt',
  //       type: 'icon'
  //     },
  //   ];
  //   this.tableSetupData.params = { deleteParams: 'timezoneIds', statusParams: 'timezoneIds' };
  //   this.tableSetupData.conditions = {
  //     showTableHeader: true,
  //     showTableFooter: true,
  //     showApplyStatus: false,
  //     showExport: false,
  //     showColumnHide: false,
  //     showFilter: false,
  //     showFilterExport: false,
  //     showSaveFilter: false,
  //     // showDateYear: true,
  //     modal: true,
  //     search: true,
  //     showButton: { buttonName: 'Add' },
  //   };
  // }
   /*************************************************************
 @Purpose :To Select Country,Currency and Timezone Tabs.
 /*************************************************************/
 onSelect(event) {
  if (event.heading == 'Interests & Hobbies') {
    this.tabName = 'Interests & Hobbies';
    this.tabs.country = true;
    // this.tabs.timezone = false;
    // this.tabs.currency = false;
   this.setBreadcrumbs();
    this.setDTableInitialData();
  } 
  // else if (event.heading == 'Currency') {
  //   this.tabs.country = false;
  //   this.tabs.timezone = false;
  //   this.tabs.currency = true;
  // //  this.setBreadcrumbs();
  //   // this.setDTableInitialData1();
  // }
  // else if (event.heading == 'TimeZone') {
  //   this.tabs.country = false;
  //   this.tabs.timezone = true;
  //   this.tabs.currency = false;
  //  // this.setBreadcrumbs();
  //   // this.setDTableInitialData2();
  // }

  }
}


 
  



