<div class="main-content-area">
    <div class="main-content-block">

        <app-sub-header [subHeaderData]="subHeaderData"></app-sub-header>

        <!-- <div class="d-flex align-items-center justify-content-between mb-2 flex-wrap">
            <div class="d-flex align-items-center">
                <div class="search position-relative has-icon-left">
                    <input type="search" class="form-control text-capitalize" placeholder="search">
                    <div class="form-control-position">
                        <em class="bx bx-search"></em>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary glow-primary" data-toggle="modal" data-target="#countryModal">
                Add
            </button>
        </div> -->

        <!-- <div class="card notification-card tabbing p-0">
            <div class="custom-tabs">
                <tabset id="content" class="tab-content">
                    <tab heading="Country" id="tab1" (selectTab)="onSelect($event)">
                        <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                        </app-responsive-data-table>
                    </tab>
                    <tab heading="Timezone">
                        <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                        </app-responsive-data-table>
                    </tab>
                    <tab heading="Currency">
                        <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                        </app-responsive-data-table>
                    </tab>
                </tabset>
            </div>
        </div> -->
        <div>
            <div class="animated fadeInUp box-wrapper notification-card card tabbing p-0">
                <div class="m-portlet  settings-wrapper approve-template custom-tabs">
                    <tabset class="tab-links tab-content" id="content">
                        <tab heading="Manage Profile Data" id="tab1" (selectTab)="onSelect($event)">
                            <!-- [hidden]="commonService.showSkeletonLoader -->
                            <div class="content-wrapper" >
                                <div class="card animated fadeIn">
                                    <form #profileForm="ngForm" (ngSubmit)="createUpdateAdmin(profileForm,admin)">
                                        <div class="text-left">
                                            <h2>User Information</h2>
                                     </div>
                                        <div class="card-body">
                                            <div class="container mt-3">
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <div class="user-profile-img edit-image mr-4">
                                                            <div class="inner-image user-image">
                                                                <img [src]="admin.photo ? uploadImageUrl + admin.photo : (croppedImage ? croppedImage : 'assets/images/NoProfile.png')" alt="no img">
                                                                <div class="upload-file">
                                                                    <label class="img-upload">
                                                                        <input class='file-input d-none' type="file" name="photo" accept="image/*"
                                                                            (change)="fileChangeEvent($event,template)" id="profile">
                                                                            <em class="bx bxs-edit-alt"></em>
                                                                    </label>
                                                                    <a href="" (click)="openfile($event)"><em class="fa fa-camera camera"
                                                                            aria-hidden="true"></em></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="mt-3 mt-sm-3" (click)="removePhoto()">
                                                            <button *ngIf="admin.photo" type="button" class="btn btn-primary" >Remove Photo</button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class=" col-form-label">First Name:</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || firstname.touched) && firstname.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="text" placeholder="Enter First Name"
                                                            name="firstname" #firstname="ngModel" [(ngModel)]="admin.firstname" required>
                                                        <div class="error-block text-left"
                                                            *ngIf="((submitted || firstname.touched) && firstname.invalid)">
                                                            {{showErrorService.showError('firstname', firstname.errors)}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class=" col-form-label">Last Name:</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || lastname.touched) && lastname.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="text" placeholder="Enter Last Name"
                                                            name="lastname" #lastname="ngModel" [(ngModel)]="admin.lastname" required>
                                                        <div class="error-block text-left"
                                                            *ngIf="((submitted || lastname.touched) && lastname.invalid)">
                                                            {{showErrorService.showError('lastname', lastname.errors)}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class="col-form-label">Phone No:</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || mobile.touched) && mobile.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="text" placeholder="Enter Phone No"
                                                            name="mobile" #mobile="ngModel" minlength="10" maxlength="14" [(ngModel)]="admin.mobile" disabled>
                                                        <!-- <div class="error-block text-left"
                                                            *ngIf="((submitted || mobile.touched) && mobile.invalid)">
                                                            {{showErrorService.showError('PhoneNo', mobile.errors)}}
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class="col-form-label">Email Address:</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || emailId.touched) && emailId.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="text" placeholder="Enter Email Address"
                                                            name="emailId" #emailId="ngModel" [(ngModel)]="admin.emailId"
                                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" disabled>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <div class="row text-right">
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn btn-primary mr-2">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </tab>

                        <tab heading="Manage Password" [active]="tabs.changePassword" (selectTab)="onSelect($event)">
                            <div class="content-wrapper">
                                <!-- [hidden]="commonService.showSkeletonLoader" -->
                                <div class="card animated fadeIn">
                                    <form #changePasswordForm="ngForm" (ngSubmit)="changePassword(changePasswordForm, change)">
                                            <div class="text-left">
                                                   <h2>Change Password</h2>
                                            </div>
                                        <div class="card-body">
                                            <div class="container mt-3">
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class="col-form-label">Old Password:</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || oldPassword.touched) && oldPassword.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="password" placeholder="Enter Old Password"
                                                            name="oldPassword" #oldPassword="ngModel" [(ngModel)]="change.oldPassword" required>
                                                        <div class="error-block text-left"
                                                            *ngIf="((submitted || oldPassword.touched) && oldPassword.invalid)">
                                                            {{showErrorService.showError('oldPassword', oldPassword.errors)}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class="col-form-label">New Password:</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || newPassword.touched) && newPassword.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="password" placeholder="Enter New Password"
                                                            name="newPassword" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$" #newPassword="ngModel" [(ngModel)]="change.newPassword" required>
                                                        <div class="error-block text-left"
                                                            *ngIf="((submitted || newPassword.touched) && newPassword.invalid)">
                                                            {{showErrorService.showError('newPassword', newPassword.errors)}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-2 col-sm-3">
                                                        <label class="col-form-label">Confirm New Password</label>
                                                    </div>
                                                    <div class="col-md-8 co-sm-7"
                                                        [ngClass]="((submitted || confirmNewPassword.touched) && confirmNewPassword.invalid)?'has-danger':''">
                                                        <input autocomplete="off" class="form-control" type="password" placeholder="Enter Confirm New password"
                                                            name="confirmNewPassword" #confirmNewPassword="ngModel" [(ngModel)]="change.confirmNewPassword" required>
                                                        <div class="error-block text-left"
                                                            *ngIf="((submitted || confirmNewPassword.touched) && confirmNewPassword.invalid)">
                                                            {{showErrorService.showError('confirmNewPassword', confirmNewPassword.errors)}}
                                                        </div>
                                                        <div class="form-control-feedback error-block" style="color: red;" *ngIf="showErr">
                                                            New password & Confirm New password not matched
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <div class="row text-right">
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn btn-primary mr-2">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </tab>

                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
            [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>