export const callAPIConstants = {
    AdminLogin: 'admin/login',
    AdminRegister: 'admin/register',
    VerifyOTP: 'admin/verifyOTP',
    SendOTP: 'admin/sendOTP',
    ForgotPassword: 'admin/forgotPassword',
    ResetPassword: 'admin/resetPassword',
    AdminLogout: 'admin/logout',
    AdminGetProfile: 'admin/profile',
    AdminEditProfile: 'admin/editProfile',
    AdminFileUpload: 'admin/fileUpload',
    AdminChangePassword: 'admin/changePassword',
    UpdateTheme: 'admin/updateThemeDetails',
    CountriesListing: 'countriesListing',
    getCountryDetails: 'getCountryDetails',
    getCurrencyDetails: 'getCurrencyDetails',
    getTimezoneDetails: 'getTimezoneDetails',
    getCountriesList: 'getCountriesList',
    addUpdateCountry: 'addUpdateCountry',
    addUpdateCurrency: 'addUpdateCurrency',
    addUpdateTimezone: 'addUpdateTimezone',
    changeCountriesStatus: 'changeCountriesStatus',
    deleteCountries: 'deleteCountries',
    timezoneListing: 'timezoneListing',
    changeTimezoneStatus: 'changeTimezoneStatus',
    deleteTimezones: 'deleteTimezones',
    getTimezoneList: 'getTimezoneList',
    getCurrenciesList: 'getCurrenciesList',
    currenciesListing: 'currenciesListing',
    deleteCurrencies: 'deleteCurrencies',
    changeCurrenciesStatus: 'changeCurrenciesStatus',
    //social media sdk
    getsocialMediaSDK: 'getSocialMediaSDK',
    addSocialMediaSDK: 'addSocialMediaSDK',
    //social media link
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',
    //global setiing 
    addGlobalSettings: 'addGlobalSettings',
    getGlobalSettings: 'getGlobalSettings',
    //payment setting
    getPaymentDetails: "getPaymentDetails",
    addPaymentDetails: "/addPaymentDetails",
    //smtp setting
    addSMTPAndSMSSettings: "addSMTPAndSMSSettings",
    getSMTPAndSMSSettings: "getSMTPAndSMSSettings",
    //email notification
    getEmailNotificationSettings:"getEmailNotificationSettings",
    addEmailNotificationSettings:"addEmailNotificationSettings",

    // email-template
    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmailColumnData: 'getEmailColumnValues',
    // EmailColumnVisibleSettings: 'emailColumnSettings',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail',

    //Faq
    FaqsListing: 'faqsListing',
    DeleteFaqs: 'deleteFAQs',
    FaqsDetails: 'getFAQDetails/',
    FaqsAddorUpdate: 'addUpdateFAQ',
    FaqsCategoryListing: 'faqCategoriesListing',
    FaqsStatusChange: 'changeFAQsStatus',
    FaqsSearchList: 'getFAQsList',
    AddUpdateFaqsCategory: 'addUpdateFAQCategory',
    DeleteFaqsCategory: 'deleteFAQCategories',
    FaqsCategoryDetails: 'getFAQCategoryDetails/',
    FaqsCategoryStatus: 'changeFAQCategoriesStatus',
    FaqsCategoriesSearchList: 'getFAQCategoriesList',


    // static && Blogs
    Listing: 'listBlog',
    DeleteBlog: 'deleteBlog',
    ChangeStatus: 'changeBlogStatus',
    GetBlogDetails: 'getBlogDetails/',
    AddUpdateBlog: 'addUpdateBlog',

    // user
    UserList: 'user/userListing',
    UserFileUpload: 'users/fileUpload',
    UserDeleteUsers: 'user/deleteUsers',
    UserChangeStatus: 'user/changeStatus',
    GetUserDetails: 'user/userProfile/',
    UserCSVforDataTable: 'user/downloadCsv',
    UserEXCELforDataTable: 'user/downloadExcel',
    UserColumnVisibleSettings: 'user/columnSettings',
    CommonColumnVisibleSettings: 'saveColumnSettings',
    SaveDownloadTemplate: 'saveTemplateSettings',
    getDownloadTemplate: 'getTemplateSettings',
    deleteDownloadTemplate: 'deleteTemplateSettings/',
    downloadUserFile: 'downloadUserFile',
    UserGetColumnData: 'user/getColumnValues',
    UserGetFilters: 'user/getFilters',

    // common
    deleteFilter: 'deleteFilter/',
    saveFilter: 'saveFilter',

};

