import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';


import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';


// plugin
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
 import { AngularEditorModule } from '@kolkov/angular-editor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { CommonModalComponent } from './components/modals/common-modal/common-modal.component';
import { TemplateDetailsComponent } from './components/modals/template-details/template-details.component';

// service

// Component
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  imports: [
   AngularEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  
   ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    SubHeaderComponent,
    ResponsiveDataTableComponent,
    CommonModalComponent,
    TemplateDetailsComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    //components
    HeaderComponent,
    SidebarComponent,
    SubHeaderComponent,
    ResponsiveDataTableComponent,
    TemplateDetailsComponent,
    DragDropModule,
    

    //modules
    FormsModule,
    CommonModule,

    //plugin
    AngularEditorModule,
    ImageCropperModule,
    BsDatepickerModule,
    TabsModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    NgxSkeletonLoaderModule
   ],
   entryComponents: [TemplateDetailsComponent, CommonModalComponent],
})
export class SharedModule { }
