<div class="main-content-area">
    <header class="sticky">

        <div class="navigation-bar">
            <nav class="navbar d-flex navbar-expand bd-navbar fixed-top">
                <div class="mr-2 float-left d-flex align-items-center" (click)="openSidebar()">
                    <ul class="nav navbar-nav">
                        <li class="nav-item mobile-menu mr-auto">
                            <a class="nav-link nav-menu-main menu-toggle hidden-xs"><i class="bx bx-menu"></i></a>
                        </li>
                    </ul>
                    <ul class="horizontal-brand nav navbar-nav">
                        <li>
                            <a href="">
                                <img class="img-fluid" src="assets/images/brand-logo.svg" alt="branding logo">
                            </a>
                        </li>
                    </ul>
                </div>
                <ul class="navbar-nav flex-row ml-auto d-md-flex">

                    <li class="nav-item dropdown dropdown-language">
                        <a class="dropdown-toggle nav-link mr-md-2" id="dropdown-flag" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i
                            class="flag-icon flag-icon-us"></i>
                            <!-- <span
                            class="selected-language">English</span> -->
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-flag">
                            <a class="dropdown-item" href="#" data-language="en"><i
                                class="flag-icon flag-icon-us mr-50"></i> English</a>
                            <a class="dropdown-item" href="#" data-language="fr"><i
                                class="flag-icon flag-icon-fr mr-50"></i> French</a>
                            <a class="dropdown-item" href="#" data-language="de"><i
                                class="flag-icon flag-icon-de mr-50"></i> German</a>
                            <a class="dropdown-item" href="#" data-language="pt"><i
                                class="flag-icon flag-icon-pt mr-50"></i> Portuguese</a>
                        </div>
                    </li>
                    <li class="nav-item nav-search">
                        <a class="nav-link nav-link-search" href="#" data-toggle="dropdown" aria-expanded="false">
                            <i class="bx bx-search"></i>
                        </a>
                        <div class="search-input">
                            <div class="search-box">
                                <div class="search-input-icon"><i class="bx bx-search primary"></i></div>
                                <input class="input" type="text" placeholder="Explore Search..." tabindex="-1" data-search="template-search">
                                <div class="search-input-close"><i class="bx bx-x"></i></div>
                            </div>
                            <ul class="search-list">
                                <li class="auto-suggestion align-items-center justify-content-between cursor-pointer current_item">
                                    <a class="align-items-center justify-content-between w-100">
                                        <div class="justify-content-start">
                                            <span class="mr-75 bx bx-error-circle"></span>
                                            <span>No results found.</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item dropdown dropdown-notification" dropdown>
                        <a class="nav-link nav-link-label" dropdownToggle type="button" id="notificationDropdown" aria-controls="dropdown-animated">
                            <i class="bx bx-bell bx-tada bx-flip-horizontal"></i>
                            <span class="badge badge-pill badge-danger badge-up">5</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right" id="notificationDropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
                            <li class="dropdown-menu-header">
                                <div class="dropdown-header d-flex justify-content-between">
                                    <span class="notification-title">7 new Notification</span>
                                    <span class="text-bold-400 cursor-pointer">Mark all as read</span>
                                </div>
                            </li>
                            <li class="scrollable-container media-list ps ps--active-y">
                                <div class="d-flex justify-content-between read-notification cursor-pointer">
                                    <div class="media d-flex align-items-center">
                                        <div class="media-left pr-0">
                                            <div class="avatar mr-1 m-0">
                                                <img class="round" src="assets/images/hero-image.jpg" alt="avatar" height="40" width="40">
                                            </div>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading"><span class="text-bold-500">New
                                                Message</span> received</h6>
                                            <small class="notification-text">You have 18 unread
                                            messages</small>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="lung-change">
                        <!-- <h6>{{ 'HOME.TITLE' | translate }}</h6> -->
                        <!-- <label> -->

                        <select #langSelect (change)="translate.use(langSelect.value)" class="form-control language-select">
                            <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                             [selected]="lang === translate.currentLang">{{ lang }}</option>
                          </select>
                        <!-- </label> -->
                    </li>
                    <li class="nav-item user-dropdown dropdown" dropdown>
                        <a class="nav-link dropdown-toggle dropdown-user-link" dropdownToggle type="button" id="userDropdown" aria-controls="dropdown-animated">
                            <div class="user-nav d-sm-flex d-none">
                                <span class="user-name">{{firstname}}  {{lastname}}</span>
                                <!-- <span class="user-status text-muted">Available</span> -->
                            </div>
                            <span>   <img class="round" style="height: 30px;" [src]="photo ? uploadImageUrl + photo : 'assets/images/NoProfile.png'" alt="no img"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" id="userDropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
                            <!-- <a class="dropdown-item" [routerLink]="[URLConstants.MY_PROFILE]"><i class='bx bx-user mr-2'></i>{{ 'Header.PROFILE' | translate }}</a>
                            <a style="cursor: pointer;" (click)="openModal(template)" class="dropdown-item"><i  class='bx bx-user mr-2'></i>{{ 'Header.CHANGEPASSWORD' | translate }}</a> -->

                            <a class="dropdown-item" style="cursor: pointer;" (click)="logout()"><i class='bx bx-log-out mr-2'></i>{{ 'Header.LOGOUT' | translate }}</a>
                        </div>
                        <ng-template #template>
                            <div class="modal-header">
                                <h4 class="modal-title pull-left">Change Password</h4>
                                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                            </div>
                            <div class="modal-body">
                                <form (ngSubmit)="changePassForm.form.valid && changePassword(changePassForm)" #changePassForm="ngForm">

                                    <div class="form-group mb-2 position-relative">
                                        <label>Old Password<sup class="bx bxs-star danger"></sup></label>
                                        <input [type]="passwordTypeOld ? 'text' : 'password'" ngModel id="password-field" type="password" name="oldPassword" class="form-control" #oldPassword="ngModel" placeholder="Old password" [ngClass]="{ 'is-invalid': (changePassForm.submitted || changePassForm.touched)  && oldPassword.invalid }"
                                            required pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$">
                                        <span [tooltip]="passwordTypeOld ? 'Hide Password' : 'Show Password'" toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                    'bx-bx-hide': !passwordTypeOld,
                                    'bx-show': passwordTypeOld
                                          }" (click)="toggleFieldTextTypeOld()"></span>

                                        <div *ngIf="(changePassForm.submitted || changePassForm.touched) && oldPassword.invalid" class="invalid-feedback">
                                            <div *ngIf="oldPassword.errors.required">Please enter old password</div>
                                            <div *ngIf="oldPassword.errors.pattern">Password should contain atleast a special character (@, $, !, %, *, ?, & or #), a capital letter and a number and length should be between 6-20</div>
                                        </div>

                                    </div>
                                    <div class="form-group mb-2 position-relative">
                                        <label>New Password<sup class="bx bxs-star danger"></sup></label>
                                        <input [type]="passwordTypeNew ? 'text' : 'password'" ngModel id="password-field" type="password" name="newPassword" class="form-control" #newPassword="ngModel" placeholder="New password" [ngClass]="{ 'is-invalid': (changePassForm.submitted || changePassForm.touched)  && newPassword.invalid }"
                                            required pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$">
                                        <span [tooltip]="passwordTypeNew ? 'Hide Password' : 'Show Password'" toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                    'bx-bx-hide': !passwordTypeNew,
                                    'bx-show': passwordTypeNew
                                          }" (click)="toggleFieldTextTypeNew()"></span>

                                        <div *ngIf="(changePassForm.submitted || changePassForm.touched) && newPassword.invalid" class="invalid-feedback">
                                            <div *ngIf="newPassword.errors.required">Please enter new password</div>
                                            <div *ngIf="newPassword.errors.pattern">Password should contain atleast a special character (@, $, !, %, *, ?, & or #), a capital letter and a number and length should be between 6-20</div>
                                        </div>

                                    </div>



                                    <button class="btn btn-primary glow position-relative btn-block mt-3">
                                        Change Password<i class="icon-arrow bx bx-right-arrow-alt"></i>
                                    </button>
                                </form>
                            </div>
                        </ng-template>
                    </li>

                    <li class="nav-item theme-setting-block-nav-link" (click)="clickEvent()">
                        <a class="nav-link theme-setting-link">
                            <i class="bx bx-cog bx-flip-horizontal"></i>
                        </a>
                        <div class="theme-setting-block" [ngClass]="status ? 'open' : null">
                            <div class="d-flex align-items-center">
                                <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
                                <div class="custom-control custom-switch theme-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchHeaderTheme" (click)="toggleTheme()">
                                    <label class="custom-control-label" for="customSwitchHeaderTheme"></label>
                                </div>
                                <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
                            </div>
                            <div class="d-flex align-items-center verticle-btn">
                                <span class="vertical-icon icon mr-1 d-block"><em class='bx bx-grid-vertical'></em></span>
                                <div class="custom-control custom-switch sidebar-switch">
                                    <input type="checkbox" class="custom-control-input" id="sidebarSwitchBtn" (click)="toggleAlignment()">
                                    <label class="custom-control-label" for="sidebarSwitchBtn"></label>
                                </div>
                                <span class="horizontal-icon icon"><em class='bx bx-grid-horizontal'></em></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</div>