import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

// Auth Guard
import { CanLoginActivate, CanAuthActivate} from "./common/service/auth-guard";

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { RegisterComponent } from "./public/register/register.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { LoginWithMobileComponent } from "./public/login-with-mobile/login-with-mobile.component";
import { LoginWithEmailComponent } from "./public/login-with-email/login-with-email.component";
import { SocialLoginComponent } from "./public/social-login/social-login.component";
import { EnterOtpComponent } from "./public/enter-otp/enter-otp.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { MasterComponent } from './modules/master/master.component';



// plugin
import { NgOtpInputModule } from  'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// module
import { SharedModule } from './shared/shared.module';
import { Settings1Component } from "./modules/settings1/settings1.component";


// Service

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "LoginWithMobile",
    canActivate: [CanLoginActivate],
    component: LoginWithMobileComponent,
    pathMatch: "full",
  },
  {
    path: "register",
    canActivate: [CanLoginActivate],
    component: RegisterComponent,
    pathMatch: "full",
  },
  {
    path: "forgot-password",
    canActivate: [CanLoginActivate],
    component: ForgotPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "social-login",
    canActivate: [CanLoginActivate],
    component: SocialLoginComponent,
    pathMatch: "full",
  },
  {
    path: "enter-otp/:phNum/:otp",
    canActivate: [CanLoginActivate],
    component: EnterOtpComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
    { path: 'master', component: MasterComponent, pathMatch: 'full' },
    { path: 'settings', component: Settings1Component, pathMatch: 'full' },
    {
      path: "dashboard",
      canActivate: [CanAuthActivate],
      loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (mod) => mod.DashboardModule
      )
    },
    {
      path: "users",
      canActivate: [CanAuthActivate],
      loadChildren: () =>
        import("./modules/user-management/users/users.module").then(
          (mod) => mod.UsersModule
        ),
    },
    {
      path: 'change-password',
      loadChildren: () => import('./modules/change-password/change-password.module')
        .then((m) => m.ChangePasswordModule),
    },
    {
      path: 'my-profile',
      loadChildren: () => import('./modules/my-profile/my-profile.module')
        .then((m) => m.MyProfileModule),
    },
    // { 
    //   path: 'setting', 
    //   loadChildren: () => import('./modules/setting/setting.module')
    //     .then(m => m.SettingModule) 
    // },
    { 
      path: 'school-management',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/school-management/school-management.module')
        .then(m => m.SchoolManagementModule) 
    },
    { 
      path: 'staff',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/staff/staff.module')
        .then(m => m.StaffModule) 
    },
    { 
      path: 'roles',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/manage-roles/manage-roles.module')
        .then(m => m.ManageRolesModule) 
    },
    { 
      path: 'ebooks',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/ebook/ebook.module')
        .then(m => m.EbookModule) 
    },
    { 
      path: 'club-channels',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/club-channels/club-channels.module')
        .then(m => m.ClubChannelsModule) 
    },
    { 
      path: 'subscriptions',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/subscriptions/subscriptions.module')
        .then(m => m.SubscriptionsModule) 
    },
    { 
      path: 'page-content',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/page-contents/page-contents.module')
        .then(m => m.PageContentsModule) 
    },
    { 
      path: 'transactions',
      canActivate: [CanAuthActivate],
      loadChildren: () => import('./modules/transactions/transactions.module')
        .then(m => m.TransactionsModule) 
    },
    {
      path: 'email-template',
      // canActivate: [CanViewListActivate],
      data: { role: 'EmailTemplate' },
      loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
        .then((m) => m.EmailTemplatesModule),
    },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
   ],
  },
  
];

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginWithMobileComponent,
    LoginWithEmailComponent,
    SocialLoginComponent,
    EnterOtpComponent,
    MainComponent,
    MasterComponent,
    Settings1Component
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [CanLoginActivate],
})
export class AppRoutingModule {}
