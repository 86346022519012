export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    RESET_PASS: '/reset-password',
    SIGNUP: '/register',
    DASHBOARD: '/dashboard',
    OTP: '/enter-otp/',
    

    /***** Authorized Pages *****/
    CHANGE_PASS: '/change-password',
    MY_PROFILE: '/my-profile',
    USERS: '/users',
    MASTER: '/master',

    SCHOOL_ADMIN: '/school-management',
    STAFF: '/staff',
    EBOOKS: '/ebooks',
    CLUB_CHANNELS: '/club-channels',
    SUBSCRIPTIONS: '/subscriptions',
    PAGE_CONTENTS: '/page-content',
    TRANSATIONS: '/transactions',
    ROLES: '/roles',



    /***** Setting Pages *****/
    SETTINGS: '/settings',
    // SETTING_GENERAL: '/setting/setting-general',
    // SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    // SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    // SETTING_SMTPSMS: '/setting/setting-smtpsms',
    // SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',

    // // Email Templates
    EMAIL_TEMPLATE_list: '/email-template',
    EMAIL_TEMPLATE_detail: '/email-template/edit-details/',
    EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',

    // // content Management
    // STATIC_PAGE: '/content/Static-Page',
    // ADD_STATIC_PAGE: '/content/Add-static-page',
    EDIT_STATIC_PAGE: '/content/edit-static-page/',
    VIEW_STATIC_DETAILS: '/content/view-static-page-details',

    // BLOGS_CATEGORY: '/content/blog-category',
    // BLOGS: '/content/blogs',
    // BLOG_ADD: '/content/blogs-add',
    // BLOG_EDIT: '/content/blog-edit/',
    // BLOG_PREVIEW: '/content/blog-preview/',

    // FAQS: '/content/faq' ,

    // // user list  
    USERS_DETAIL: '/users/view-details/',
    ADDUPDATEUSER: '/users/add-update-user/',



};


