import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

// environment
import { environment } from 'src/environments/environment';

// Interface
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// Constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

// Services
import { CommonService } from 'src/app/common/service/common.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';

// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-settings1',
  templateUrl: './settings1.component.html',
  styleUrls: []
})
export class Settings1Component implements OnInit {
  tableSetupData: any = {};
  public accessPermission: any;
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  public tabName: any = 'Profile Data';

  tabs: any = {};
  change: any = {};
  uploadImageUrl = environment.uploadImgUrl;

  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;

  constructor(public commonService: CommonService, public showErrorService: ShowErrorService, public bsModalRef: BsModalRef, private modalService: BsModalService, private router: Router, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.tabs.profile = true;
    this.getAdminProfile();
  }

// To set dynamic breadcrump

  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'Settings', url: this.URLConstants.SETTINGS },
      { localeKey:  this.tabName, url: null },
    ];
    this.subHeaderData = {
      breadcrumbs: this.breadcrumbs,
    };
  }

  /****************************************************************************
  @Purpose     : Get Admin Profile
  @Parameter   : NA
  @Return      : admin
  /****************************************************************************/
  public admin: any = { lastname: '', firstname: '', mobile: '', photo: '', twitter: ''};
  public email;
  getAdminProfile() {
    this.commonService.callApi(this.callAPIConstants.AdminGetProfile, '', 'get', false, true).then((success) => {
      if (success.status === 1) {
        this.admin.firstname = success.data.firstname;
        this.admin.lastname = success.data.lastname;
        this.admin.mobile = success.data.mobile;
        this.admin.emailId = success.data.emailId;
        this.admin.photo = success.data.photo;
        
      } else { this.showErrorService.popToast('error', success.message); }
    });
  }
  /****************************************************************************/
  /****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
  /****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';

  imageCropped(event) {
    this.croppedImage = event.base64;

    const ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);

  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
    fd.append('file', this.file);
    this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fd, 'post', true, true).then((success) => {
      (success.status === 1) ? this.admin.photo = success.data.filePath : this.showErrorService.popToast('error', success.message);
    });
  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  // removePhoto() {
  //   console.log('removePhoto method called', this.croppedImage);
  //   if (this.admin.photo) {
  //     this.admin.photo = null;
  //     this.croppedImage = '';
  //   }
  // }


  /*************************************************************
@Purpose :To Select Country,Currency and Timezone Tabs.
/*************************************************************/
  onSelect(event) {
    if (event.heading == 'Manage Profile Data') {
      this.tabName = 'Profile Data';
      this.tabs.profile = true;
      this.tabs.changePassword = false;
      this.setBreadcrumbs();
    }
    else if (event.heading == 'Manage Password') {
      this.tabName = 'Change Password';
      this.tabs.profile = false;
      this.tabs.changePassword = true;
       this.setBreadcrumbs();
    }
  }

 
   /*************************************************************
  @Purpose     : Call API to Change Password
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  submitted: boolean = false;
  public showErr = false;
  changePassword(form, user) {
    this.submitted = true;
    if (form.valid) {
      const data = {
        oldPassword: form.value.oldPassword,
        newPassword: form.value.newPassword,
      };
      if (form.value.newPassword !== form.value.confirmNewPassword) {
        this.showErr = true;
        this.submitted = true;
      } else {
        this.showErr = false;
        this.commonService.callApi(this.callAPIConstants.AdminChangePassword, data, 'post', false, false).then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            form.reset();
            this.submitted = false;
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
      }
    }
  }
  /*************************************************************/
  submitted1: boolean = false;
  createUpdateAdmin(form, admin) {
    this.submitted1 = true;
    if(form.valid) {
  this.commonService.callApi(this.callAPIConstants.AdminEditProfile, admin, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', 'Updated Successfully!');
          this.localStorageService.setToken('firstname', admin.firstname);
          this.localStorageService.setToken('lastname', admin.lastname);
          this.localStorageService.setToken('photo', admin.photo);
        } else { this.showErrorService.popToast('error', success.message); }
      });
    }
  }
}







